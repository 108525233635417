@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Nunito+Sans:wght@400;700;800&display=swap');

@font-face {
  font-family: 'Caxton Book BT';
  font-style: normal;
  font-weight: normal;
  src: local('Caxton Book BT'), url('../fonts/CAXTON-REGULAR.woff') format('woff');
}


$font-family-sans-serif: "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1.125rem;