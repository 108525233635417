@import "fonts";

$body-color: #000;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  smxl: 1080px,
  xl: 1200px,
  xxl: 1400px
);

@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-utilities";

body {
  background: #ece8da;
}

.font-roboto {
  font-family: Roboto, $font-family-sans-serif;
}

.font-caxton {
  font-family: "Caxton Book BT", $font-family-sans-serif;
}

.header {
  @include media-breakpoint-up(smxl) {
    background: url("../images/hero-desktop.jpg") top center;
    background-size: cover;
    height: 700px;
  }

  position: relative;

  .moto-container {
    color: #101010;
    position: absolute;
    //bottom: 440px;
    //bottom: 380px;
    bottom: 335px;
    left: 0;
    right: 0;

    @include media-breakpoint-between(smxl, xxl) {
      font-size: 0.875rem;
    }
  }

  .badge-container {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;

    .badge {
      color: #fff;
      background: #282828;
      border-radius: 30px;
      padding: 20px 35px;

      a {
        color: #ece8da;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      h1 {
        font-size: 1.375rem;

        @include media-breakpoint-up(smxl) {
          font-size: 1.875rem;
          line-height: 1.875rem;
        }
      }
    }

    .sold-out-container {
      position: relative;

      .sold-out {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          bottom: -10px;
        }

        .inner {
          font-size: 1rem;
          background: rgba(215, 182, 0, 0.85);
          transform: rotate(-5deg);
          padding: 0 5px;
          color: #000;
          font-weight: 800;
        }
      }
    }
  }
}

.btn-buy {
  color: #ece8da;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  border-radius: 20px;
  background-color: #014d2c;
  padding: 10px 25px;

  font-size: 1rem;
  line-height: 1rem;

  &.big {
    border-radius: 40px;
    padding: 20px 40px;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  &:hover {
    color: #fff;
  }

  &.disabled {
    opacity: .5;
    &:hover {
      color: #ece8da;
    }
  }
}

.conferences {
  .conference {
    .image.in-past a {
      display: block;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: " ";
        background: rgba(50, 50, 50, 0.50);
      }
    }

    .image {
      position: relative;

      .past-conference-text-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        
        .past-conference-text {
          color: #fff;
          font-weight: bold;
          font-style: italic;
          transform: rotate(-45deg);
          font-size: 2rem;
        }
      }
    }

    .title {
      padding: 15px;
      text-align: center;

      h3, .curator {
        font-size: 1.375rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.625rem;
        }
      }

      .details-link {
        color: #014d2c;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;

        &.disabled {
            opacity: .5;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      width: calc(100%/5);
    }
  }
}

.past-conferences {
  color: #fff;
  padding-top: 50px;
  position: relative;
  background: url("../images/past-events-bg.jpg") top center;

  .content-container {
    position: relative;
    z-index: 10;
  }
  .mix-bg {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    display: block;
    background: #ece8da;
  }

  p {
    text-align: center;
  }

  h2 {
    text-align: center;
    font-size: 1.75rem;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }

  .button-container {
    position: absolute;
    bottom: -23px;
    left: 0;
    right: 0;
    text-align: center;
  }

}

.conference-edition {
  background: #f6f4ed;

  &.pinky {
    background: #f2d9dc;
  }

  h2 {
    color: #1a5e3e;
  }

  .speaker-photo {
    width: 100%;
    max-width: 280px;

  }

  .play-button {
    display: flex;
    text-decoration: none;
    text-transform: uppercase;
    color: #014d2c;
  }
}
.schedule {
  padding-top: 50px;
  margin-bottom: 100px;
  .schedule-entry {
    .col.time {
      border-right: 1px solid #1a5e3e;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: -5px;
        top: 16px;
        width: 10px;
        height: 10px;
        background: #1a5e3e;
        border-radius: 50%;
      }
    }
  }
}

.footer {
  color: #8f8f8a;
  padding-top: 15px;
  padding-bottom: 30px;

  .footer-inner {
    border-top: 1px solid #d6d2c5;
  }

  .links a {
    display: inline-block;
    color: #8f8f8a;
    text-decoration: none;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .middle {
    border-top: 1px solid #d6d2c5;
    border-bottom: 1px solid #d6d2c5;

    @include media-breakpoint-up(md) {
      border-top: 0;
      border-bottom: 0;
      border-left: 1px solid #d6d2c5;
      border-right: 1px solid #d6d2c5;
    }
  }
}